import React, { FC } from "react";
import { BasicTemplate } from "@templates/BasicTemplate";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { OurProjectsSection } from "@components/molecules/OurProjectsSection";
import { Contact } from "@components/molecules/Contact";
import { graphql } from "gatsby";
import { ChildImageSharp } from "@interfaces/childImageSharp";
import styled from "styled-components";
import { breakpoints } from "@theme/breakpoints";
import { getRealisedProjectSlug } from "@utils/getRealisedProjectSlug";

const StyledOurProjectsSection = styled(OurProjectsSection)`
  ${breakpoints.tabletMax`
    margin: 150px auto 0!important;
  `};

  ${breakpoints.tabletMax`
    margin: 120px auto 0!important;
  `};
`;

const RealisedProjects: FC<{
  data: {
    allStrapiRealisedProject: {
      nodes: {
        strapi_id: number;
        name: string;
        featuredCardText: string;
        featuredImage: {
          localFile: ChildImageSharp;
        };
      }[];
    };
  };
}> = ({ data }) => {
  const { t } = useTranslation("common");

  return (
    <BasicTemplate
      title={t("title-realised-projects")}
      description={t("description-realised-projects")}
      isAnotherThanIndex
    >
      <StyledOurProjectsSection
        secondary
        headlineAs="h1"
        projects={data.allStrapiRealisedProject.nodes.map(
          ({ featuredImage, featuredCardText, strapi_id, name }) => ({
            featuredImage,
            featuredText: featuredCardText,
            name: name,
            slug: getRealisedProjectSlug(strapi_id),
          })
        )}
      />
      <Contact />
    </BasicTemplate>
  );
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    allStrapiRealisedProject(
      filter: { locale: { eq: $language } }
      sort: { order: DESC, fields: [createdAt] }
    ) {
      nodes {
        strapi_id
        name
        featuredImage {
          localFile {
            childImageSharp {
              gatsbyImageData(
                quality: 90
                placeholder: BLURRED
                layout: FULL_WIDTH
              )
            }
          }
        }
        featuredCardText
      }
    }
  }
`;

export default RealisedProjects;
